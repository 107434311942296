.order-info-wrapper span {
  border: 2px solid #46a0ff;
  display: inline-block;
  padding: 10px;
}

#area-zone-seat-icon {
  cursor: pointer;
}

#area-zone-seat-link {
  cursor: pointer;
}