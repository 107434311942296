#event_ticket_category_table {

  .material-icons {
    font-size: 24px;
  }

  td {
    vertical-align: middle;
  }

  tr td:last-child{
    width: 140px;
  }

}


